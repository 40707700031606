<template>
  <div>
    <a-button-group>
      <a-button type="danger" size="small" :disabled="item.status !== 'in_progress'" @click="confirmClose">关闭</a-button>
    </a-button-group>
  </div>
</template>

<script>
import { measurementTaskClose } from "@/apis/calibration";

export default {
  props: ["item"],
  data() {
    return {};
  },
  methods: {
    confirmClose() {
      const close = () => this.close();

      this.$confirm({
        title: "确认关闭测量任务？",
        content: "关闭后，接下来包裹的重量，体积测量数据无效并不作统计！谨慎操作！",
        onOk() {
          close();
        },
      });
    },
    close() {
      measurementTaskClose({ id: this.item.id }).then((data) => {
        this.$message.success("关闭成功");
        this.$emit("update", data);
      });
    },
  },
};
</script>

<style scoped></style>
